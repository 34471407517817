import React from 'react';
import {AboutUs1,AboutUs2, AboutUs3, AboutUs4, AboutUs5, AboutUs6} from '../../components/AboutUsComponents/index';


const AboutUs =()=> {
    return (
        <>
            <AboutUs1/>
            <AboutUs2/>
            <AboutUs3/>
            <AboutUs4/>
            <AboutUs5/>
            <AboutUs6/>
        </>
    )
}
  export default AboutUs;