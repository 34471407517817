import React from 'react';
import {HomeComponent1,HomeComponent2,HomeComponent3,HomeComponent4,HomeComponent5,HomeComponent6,HomeComponent7,
    HomeComponent8} from '../../components/HomeComponents/index'



const Home = () => {


    return (
        <>
            <HomeComponent1/>
            <HomeComponent2/>
            <HomeComponent3/>
            <HomeComponent4/>
            <HomeComponent5/>
            <HomeComponent6/>
            <HomeComponent7/>
            <HomeComponent8/>
        </>
    );
}

export default Home;