import React from 'react';
import {FAQ1,FAQ2,FAQ3} from "../../components/FAQComponents";


const FAQPage =()=> {
    return (
        <>
            <FAQ1/>
            <FAQ2/>
            <FAQ3/>
        </>
    )
}

export default FAQPage;