import React from 'react';
import {TermsAndConditions1,TermsAndConditions2,TermsAndConditions3} from '../../components/TermsAndConditionsComponents/index';





const TermsAndConditions =()=>{
    return(
        <>
            <TermsAndConditions1/>
            <TermsAndConditions2/>
            <TermsAndConditions3/>
        </>
    )
}

export default TermsAndConditions;