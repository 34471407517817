import React from 'react';
import Read from '../../../assets/images/AppFeaturesImages/Read.svg';
import classes from './AppComponent1.module.css';

const AppComponent1 =()=> {
    return(
        <div className="container">
            <div className={classes.inside}>
                <div className={classes.left}>
                    <div>
                        <div className={classes.title}>Multi-Accounts</div>
                        <div className={classes.sibTitle}>Create business and personal accounts</div>
                        <div className={classes.text}>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
                            purus sit amet luctus venenatis</div>
                    </div>
                    <div className={classes.readDiv}>
                        <div className={classes.iconDiv}>
                            <img src={Read} alt={""} className={classes.readIcon}/>
                        </div>
                      Read More
                    </div>


                </div>
                <div className={classes.right}>

                </div>
            </div>
        </div>
    )
}

export default AppComponent1;
