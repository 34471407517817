import React from 'react';
import {Blog1, Blog2,Blog4, Blog5} from '../../components/BlogComponents/index';



const Blog = () => {

    return (
        <>
            <Blog1/>
            <Blog2/>
            <Blog4/>
            <Blog5/>
        </>
    )
}

export default Blog;