import React from 'react';
import {AppComponent1} from '../../components/AppFeaturesComponents/index';


const AppFeatures =()=> {
    return (
        <>
            <AppComponent1/>
        </>
    )
}


export default AppFeatures;